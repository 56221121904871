import { useEffect, useState } from 'react';
import useSWR, { SWRConfiguration, cache } from 'swr';
import { isEmpty } from 'utils/primitives';

export type Props<T> = {
  /**
   * The initial state of the previous data holder (useState).
   */
  initialState: T;
  /**
   * Identifier of your query, should include your dependencies in the form of a string
   */
  queryKey: string;
  fetcher: () => Promise<T>;
  /**
   * SWR config overrides
   */
  config?: SWRConfiguration;
};

const useCachedTableRequest = <T>({
  initialState,
  fetcher,
  queryKey,
  config,
}: Props<T>) => {
  const [state, setState] = useState<T>(initialState);
  const { data, revalidate, isValidating, mutate, ...swrResponse } = useSWR<T>(
    queryKey,
    fetcher,
    {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
      ...config,
      revalidateOnMount: false,
    }
  );

  useEffect(() => {
    // this shouldn't be necessary, as SWR has a revalidateOnMount option, but it wasn't
    // working consistently, and the isValidating flag was staying true while not revalidating
    if (config?.revalidateOnMount === false) return;
    revalidate();
  }, []);

  useEffect(() => {
    // avoids clearing the previous table data while loading new, similar to keepPreviousData on react-query
    // if you cancel the revalidation, it will still clear the cached data and fetch everything again on the next query
    if (isEmpty(data)) return;
    setState(data);
  }, [data]);

  return {
    ...swrResponse,
    data: state,
    isLoading: isValidating && !data, // didn't have anything in cache and fetched the data again
    isValidating,
    revalidate,
    mutate,
    cache,
  };
};

export default useCachedTableRequest;
