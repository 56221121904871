import React from 'react';
import { Column } from 'react-table';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { EmptyState, StateType } from '@tamo-marketplace/genie';
import { useTheme } from '@tamannabit/design-system.foundations.context.main-theme';
import { ImportRoutes } from 'constants/routes';
import { Import, ImportDetails, PaginationFields } from 'models';
import { FetchParameters } from 'components-shared/table/BaseTable';
import { BaseTableStyle } from 'styles';
import { getImportStatusMetadata } from 'utils/import';
import { DATE_TIME_FORMAT } from 'constants/common';
import { getStyleColorValue } from 'utils/color';
import useProductImportHistory from 'hooks/import/useProductImportHistory';
import BaseTable from 'components-shared/table/BaseTable';
import { StyleColor, Styleable } from 'models/utils';
import {
  VENDOR_CATALOGS_IMPORT_HISTORY_EMPTY_TABLE_SUBTITLE,
  VENDOR_CATALOGS_IMPORT_HISTORY_EMPTY_TABLE_TITLE,
  VENDOR_CATALOGS_IMPORT_HISTORY_TABLE_IN_PROGRESS,
  VENDOR_CATALOGS_IMPORT_INVENTORY_BUTTON_TEXT,
} from 'constants/textResources';

const Indicator = styled.div<{ color: StyleColor }>(({ color }) => ({
  height: '16px',
  width: '16px',
  borderRadius: '50%',
  backgroundColor: getStyleColorValue(color),
}));

const IndicatorWithText = ({
  color,
  text,
}: {
  color: StyleColor;
  text: string;
  importDetailsId: number;
}) => (
  <Flex alignItems="center" style={{ cursor: 'pointer' }}>
    <Indicator color={color} />
    <Text ml="8px">{text}</Text>
  </Flex>
);

const StatusIndicator = ({
  status,
  importDetailsId,
}: {
  status: Import.Status;
  importDetailsId: number;
}) => {
  const {
    theme: { semanticColors },
  } = useTheme();

  const { label, color } = getImportStatusMetadata(status, semanticColors);
  return (
    <IndicatorWithText
      color={color}
      text={label}
      importDetailsId={importDetailsId}
    />
  );
};

const tableColumns: Column<ImportDetails>[] = [
  {
    Header: 'File Name',
    accessor: 'filename',
    Cell: ({ row }) => {
      const importDetails = row?.original;
      return (
        <Text
          maxWidth="100%"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {importDetails.filename}
        </Text>
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }) => {
      const importDetails = row?.original;

      const importStatus =
        (importDetails.status === Import.Status.FINISHED &&
          importDetails.error_count > 0) ||
        importDetails.status === Import.Status.ERROR
          ? Import.Status.FINISHED_WITH_ERRORS
          : importDetails.status;

      return (
        <StatusIndicator
          status={importStatus}
          importDetailsId={importDetails.id}
        />
      );
    },
  },
  {
    Header: 'Lines',
    accessor: 'lines',
    maxWidth: 96,
    Cell: ({ row }) => {
      const importDetails = row?.original;
      const { lines } = importDetails;

      return lines >= 100000 ? '99999+' : `${lines}`;
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    maxWidth: 96,
    Cell: ({ row }) => {
      const importDetails = row?.original;
      const { type } = importDetails;
      return type;
    },
  },
  {
    Header: 'Errors',
    accessor: 'error_count',
    maxWidth: 96,
    Cell: ({ row }) => {
      const { error_count } = row.original;

      return error_count
        ? error_count >= 100000
          ? '99999+'
          : `${error_count}`
        : 'None';
    },
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({ row }) => {
      const importDetails = row.original;
      const fullDate = dayjs(importDetails.created_at).format(DATE_TIME_FORMAT);
      return <>{fullDate}</>;
    },
  },
  {
    Header: 'Finished',
    accessor: 'updated_at',
    Cell: ({ row }) => {
      const importDetails = row.original;
      if (importDetails?.status === Import.Status.PROCESSING) {
        return VENDOR_CATALOGS_IMPORT_HISTORY_TABLE_IN_PROGRESS;
      }
      const fullDate = dayjs(importDetails.updated_at).format(DATE_TIME_FORMAT);
      return <>{fullDate}</>;
    },
  },
];

export type TableProps = {
  data: any;
  colors?: {
    headerColor: StyleColor;
    rowHoverColor: StyleColor;
  };
  searchTerm?: string;
  fetchParameters: PaginationFields;
  setFetchParameters: React.Dispatch<React.SetStateAction<unknown>>;
} & Styleable;

const ImportHistoryTable = ({
  searchTerm,
  fetchParameters,
  setFetchParameters,
  style,
  ...props
}: TableProps) => {
  const { query, push: pushToRoute } = useRouter();
  const { catalogId: catalogId } = query as { catalogId: string };

  const { data, isLoading: isLoadingProduct } = useProductImportHistory({
    catalogId: catalogId,
    pagination: fetchParameters,
    pageName: 'products',
  });

  const fetchData = React.useCallback(
    async (parameters: FetchParameters<ImportDetails>) => {
      const { pageIndex: page } = parameters;

      setFetchParameters({ page, size: 5 });
    },
    [setFetchParameters]
  );

  const NoDataEmptyState = () => (
    <EmptyState
      stateType={StateType.EMPTY_TABLE}
      title={VENDOR_CATALOGS_IMPORT_HISTORY_EMPTY_TABLE_TITLE}
      subtitle={VENDOR_CATALOGS_IMPORT_HISTORY_EMPTY_TABLE_SUBTITLE}
      action={() =>
        pushToRoute(ImportRoutes.Import + `?catalogId=${catalogId}`)
      }
      actionTitle={VENDOR_CATALOGS_IMPORT_INVENTORY_BUTTON_TEXT}
    />
  );

  const isLoading = isLoadingProduct;
  return (
    <BaseTableStyle sx={style} {...props}>
      <BaseTable<ImportDetails>
        fetchData={fetchData}
        loading={isLoading}
        totalRows={data?.totalElements}
        tableOptions={{
          data: searchTerm
            ? data.data?.filter((catalog) => {
                return catalog.filename
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase());
              })
            : data.data,
          columns: tableColumns,
          manualPagination: true,
          manualSortBy: true,
          autoResetPage: false,
          autoResetSortBy: false,
          pageCount: data?.totalPages,
        }}
        NoDataEmptyState={NoDataEmptyState}
        initialState={{
          sortBy: [{ id: 'name', desc: false }],
        }}
        onRowClick={(catalog) =>
          pushToRoute({
            pathname: `/${catalog.type}/imports/${catalog.id}`,
          })
        }
      />
    </BaseTableStyle>
  );
};

export default ImportHistoryTable;
