import { ThemeConfig } from '@tamannabit/design-system.foundations.theme';
import { IconType } from 'components-shared/icon/Icon';
import { Import, ImportType, ImportTypeEnum } from 'models';
import { Nullable, StyleColor } from 'models/utils';

export const isImportType = (importType: string): importType is ImportType => {
  return (Object.values(ImportTypeEnum) as string[]).includes(importType);
};

export const getImportTypeFromString = (
  importType: Nullable<string>
): ImportType | null => {
  return importType && isImportType(importType)
    ? (importType as ImportType)
    : null;
};

export const getImportTypeMetadata = (
  importType: ImportType
): { label: string; icon: IconType } => {
  switch (importType) {
    case 'inventory':
      return {
        label: 'Inventory',
        icon: 'available_stock',
      };
    case 'products':
      return {
        label: 'Product',
        icon: 'shopping_bag',
      };
  }
};

export const getImportStatusMetadata = (
  status: Import.Status,
  semanticColors: ThemeConfig['semanticColors']
): { description: string; label: string; color: StyleColor } => {
  switch (status) {
    case Import.Status.ERROR:
      return {
        description: 'Unexpected Error',
        label: 'Unexpected Error',
        color: semanticColors.error as StyleColor,
      };
    case Import.Status.FINISHED_WITH_ERRORS:
      return {
        description: 'Finished With Errors',
        label: 'Finished With Errors',
        color: semanticColors.error as StyleColor,
      };
    case Import.Status.TIMEOUT:
      return {
        description: 'Something went wrong, try again later',
        label: 'Something went wrong, try again later',
        color: semanticColors.error as StyleColor,
      };
    case Import.Status.FINISHED:
      return {
        description: 'Finished successfully',
        label: 'Successful',
        color: semanticColors.success as StyleColor,
      };
    case Import.Status.SCHEDULED:
      return {
        description: 'Was scheduled',
        label: 'Scheduled',
        color: semanticColors.info as StyleColor,
      };
    default:
      return {
        description: 'In Progress',
        label: 'In Progress',
        color: semanticColors.info as StyleColor,
      };
  }
};
