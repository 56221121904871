import { getProductImportHistory } from 'api/services/productImportHistory';
import useCancelToken from 'hooks/useCancelToken';
import usePageableTableRequest from 'hooks/usePageableTableRequest';
import { getKebabCase } from 'utils/primitives';

export type Props = {
  pagination: unknown;
  pageName: string;
  catalogId: string;
};

const useProductImportHistory = ({
  pagination,
  pageName,
  catalogId,
}: Props) => {
  const { getCancelToken } = useCancelToken();
  const data = usePageableTableRequest({
    queryKey: getKebabCase(
      'product-categories-table',
      ...Object.values(pagination)
    ),
    fetcher: fetchProductCategories,
  });

  async function fetchProductCategories() {
    return await getProductImportHistory({
      catalogId,
      pagination,
      pageName,
      cancelToken: getCancelToken(true),
    });
  }
  return data;
};

export default useProductImportHistory;
