/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum ImportTypeEnum {
  inventory = 'inventory',
  products = 'products',
}

export type ImportType = keyof typeof ImportTypeEnum;

export interface LoginInfo {
  username: string;
  password: string;
}

export interface LoginResult {
  email: string;
  id: string;
  roles: string[];
  token: string;
  username: string;
}

export namespace Import {
  export enum Status {
    FINISHED_WITH_ERRORS = 'FINISHED_WITH_ERRORS',
    FINISHED = 'FINISHED',
    PROCESSING = 'PROCESSING',
    SCHEDULED = 'SCHEDULED',
    TIMEOUT = 'TIMEOUT',
    ERROR = 'ERROR',
  }
}

export interface ApiResponse<T> {
  reportUrl?: string | null;
  status?: string | null;
  hasError: boolean;
  wasCanceled: boolean;
  statusCode: number;
  data: T | null;
  error: ApiError | null;
}

export type FileResponse = {
  fileData?: any;
  fileName?: string;
  fileUrl?: string;
  id?: string;
  reportUrl?: string | null;
  status?: string | null;
};

export interface ApiError {
  errorCode: string;
  errors: string[];
  message: string;
  status: string;
}

export interface Price {
  amount: number;
  currency: string;
}

export interface ImportError {
  reason: string;
  line: number;
  created_at: string;
}

export interface ImportInventoryError extends ImportError {
  sku: string;
  variant_sku: string;
  price?: Price;
  salePrice?: Price | null;
  stock?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ImportProductError extends ImportError {}

export type ImportErrorType = ImportInventoryError | ImportProductError;

export interface ImportDetails {
  id: number;
  filename: string;
  status: Import.Status;
  brand: string;
  type: string;
  lines: number;
  errors: ImportErrorType[];
  error_count: number;
  brand_manager_id: string;
  created_at: string;
  updated_at: string;
}

export interface Pageable<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
  last: boolean;
  sort: Sort;
  numberOfElements: number;
  first: boolean;
  size: number;
  number: number;
  empty: boolean;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export type PaginationFields = {
  page?: number;
  size?: number;
  sort?: string;
  filter?: string;
  name?: string;
  filename?: string;
  status?: Import.Status;
  lines?: string;
  pagination?: string[];
  searchTerm?: string;
};

export type PimsReviewStatus = 'IN_REVIEW' | 'DONE';

export interface PimsReview {
  uuid: string;
  url: string;
  status: PimsReviewStatus;
  createdAt: string;
  updatedAt: string;
}
export interface Price {
  amount: number;
  currency: string;
}

export type Catalog = {
  id: string;
  vendor: CatalogVendor;
};
export interface CatalogVendor {
  id: number;
  name: string;
  tenantId: string;
  verticals: string[];
}

export interface CategoryResponse {
  categoryId: string;
  categoryKey: string;
  id: number;
  name: string;
  url: string;
}

export enum Verticals {
  WOMEN = 'WOMEN',
  MEN = 'MEN',
  HOME = 'HOME',
  KIDS = 'KIDS',
  PRESTIGE = 'PRESTIGE',
}
export interface BrandCreationRequest {
  name: string;
  verticals: string[];
  translation: string;
  settings: {
    percentageQuantityAvailability: number;
    minimumSafetyQuantityThreshold: number;
  };
}

export interface BrandUpdateRequest {
  id: string;
  name: string;
  verticals: string[];
  translation: string;
  settings: {
    percentageQuantityAvailability: number;
    minimumSafetyQuantityThreshold: number;
  };
}
export interface BrandsResponse {
  id: string;
  name: string;
  verticals: string[];
  urls: { [key: string]: string };
  translation: string;
  isFixed: boolean;
  settings: {
    minimumSafetyQuantityThreshold: number;
    percentageQuantityAvailability: number;
  };
}

export interface BrandsResponseWrapper {
  data: BrandsResponse;
  errors: string[];
}

export interface ProductsAggregatorResponse {
  id: string;
  externalId: string;
  sku: string;
  name: string;
  brandName: string;
  numberOfVariants: string;
}

export type ExportsStatus =
  | 'FINISHED_WITH_ERRORS'
  | 'FINISHED'
  | 'SCHEDULED'
  | 'PROCESSING';

export interface ExportsAggregatorResponse {
  id: string;
  status: ExportsStatus;
  createdAt: string;
  reportUrl: string;
}

export interface VariantPrice {
  value: number;
  sale: number;
  currency: string;
}
export interface ProductVariantsResponse {
  sku: string;
  name: string;
  price: VariantPrice;
  inventory: {
    quantityAvailable: number;
    quantityOnHands: number;
    quantityReserved: number;
    quantityBuffered: number;
    locationId: string;
  };
}

export type ProductOverviewPrice = {
  min: number;
  max: number;
  currency: string;
};

export type ProductOverview = {
  vendorName: string;
  vendorRef: string;
  brandName: string;
  productName: string;
  sku: string;
  barcode?: string;
  categories?: string[];
  colours?: string[];
  sizes?: string[];
  prices?: ProductOverviewPrice[];
};
