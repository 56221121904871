import { CancelTokenSource } from 'axios';
import fetcher from 'api/fetcher';
import { ApiResponse, ImportDetails, Pageable, PaginationFields } from 'models';
import {
  apiErrorBoundary,
  getPaginationQuery,
  parseApiResponse,
} from 'utils/api';
import { Nullable } from 'models/utils';

const ENDPOINTS = {
  getAllProducts: `/products/imports`,
  getAllInventory: `/inventory/imports`,
};

type RequestOptions = {
  cancelToken?: CancelTokenSource;
};

export const getProductImportHistory = async ({
  pagination,
  catalogId,
  cancelToken,
}: RequestOptions & {
  pagination?: Nullable<PaginationFields>;
  pageName: string;
  catalogId: string;
}): Promise<ApiResponse<Pageable<ImportDetails>>> =>
  apiErrorBoundary(async () => {
    const paginationQuery = getPaginationQuery(pagination);

    const productsUrl =
      `${ENDPOINTS.getAllProducts}?${paginationQuery}` +
      (catalogId != undefined ? `&catalogId=${catalogId}` : '');

    const response1 = await fetcher<Pageable<ImportDetails>>(productsUrl, {
      cancelToken: cancelToken?.token,
    });

    if (response1.data) {
      for (let i = 0; i < response1.data.content.length; i++) {
        response1.data.content[i]['type'] = 'products';
      }
    }

    const inventoryUrl =
      `${ENDPOINTS.getAllInventory}?${paginationQuery}` +
      (catalogId != undefined ? `&catalogId=${catalogId}` : '');

    const response2 = await fetcher<Pageable<ImportDetails>>(inventoryUrl, {
      cancelToken: cancelToken?.token,
    });

    if (response2.data) {
      for (let i = 0; i < response2.data.content.length; i++) {
        response2.data.content[i]['type'] = 'inventory';
      }
    }

    response1.data.content.push(...response2.data.content);
    response1.data.content.sort((a, b) =>
      a.created_at > b.created_at ? -1 : 1
    );

    return parseApiResponse(response1);
  }, `Error fetching Product Categories with options '${getPaginationQuery(pagination)}'`);
