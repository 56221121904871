import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { PaginationFields } from 'models';
import MainContentLayout from 'components-shared/MainContentLayout';
import ImportHistoryTable from 'components/import/ImportHistoryTable';

const MainImportHistory = styled.main(
  ({ theme: { colors } }) => css`
    background-color: ${colors.neutral20};
  `
);

interface Props {
  searchTerm: string;
}

const ImportHistory = ({ searchTerm }: Props) => {
  const defaultFetchParameters = {
    page: 0,
    size: 10,
    name: '',
  };

  const [fetchParameters, setFetchParameters] = useState<PaginationFields>(
    defaultFetchParameters
  );

  return (
    <MainImportHistory>
      <MainContentLayout
        left={
          <ImportHistoryTable
            searchTerm={searchTerm}
            fetchParameters={fetchParameters}
            setFetchParameters={setFetchParameters}
            data={undefined}
          />
        }
      />
    </MainImportHistory>
  );
};

ImportHistory.getInitialProps = async () => {
  return {};
};

export default ImportHistory;
