import dayjs from 'dayjs';
import { ApiResponse, Pageable } from 'models';
import { DATE_TIME_FORMAT } from 'constants/common';
import { Nullable } from 'models/utils';
import useCachedTableRequest, {
  Props as CachedRequestProps,
} from 'hooks/useCachedTableRequest';

export type TableState<T> = {
  data: Nullable<T[]>;
  totalElements: number;
  totalPages: number;
  updateTime: Nullable<string>;
};

const fetchData = async <T>(
  fetchApiData: () => Promise<ApiResponse<Pageable<T>>>
) => {
  const { data: responseData, wasCanceled, hasError } = await fetchApiData();
  if (hasError || wasCanceled) return null;
  return {
    data: responseData?.content ?? [],
    totalElements: responseData?.totalElements ?? 0,
    totalPages: responseData?.totalPages ?? 0,
    updateTime: dayjs().format(DATE_TIME_FORMAT),
  };
};

type Props<T> = {
  fetcher: () => Promise<ApiResponse<Pageable<T>>>;
} & Pick<CachedRequestProps<T>, 'queryKey' | 'config'>;

const usePageableTableRequest = <T = unknown>({
  queryKey,
  fetcher: apiFetcher,
  config,
}: Props<T>) => {
  const data = useCachedTableRequest({
    queryKey,
    fetcher: () => fetchData(apiFetcher),
    initialState: {
      data: null,
      totalElements: 0,
      totalPages: 0,
      updateTime: null,
    } as TableState<T>,
    config,
  });
  return data;
};

export default usePageableTableRequest;
